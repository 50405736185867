import axios from "axios";
import { indonesia_payment } from "../../js/path";
import moment from "moment";

export default {
  data() {
    return {
      screenshot: "",
      message: "",
      params: "",
      filter: null,
      pageNo: null,
      tableData: [],
      sortBy: "id",
      sortDesc: true,
      filterOn: [],
      currentPage: 1,
      fields: [
        {
          key: "id",
          visible: true,
        },
        {
          key: "member_name",
          label: "Member",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "member_id",
          label: "Member ID",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "type",
          label: "Member Type",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_items",
          label: "Title",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_number",
          label: "Order Number",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "order_id",
          label: "Transaction ID",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "payment_date",
          label: "Payment Date & Time",
          class: "c_touchpt_header",
          visible: true,
          formatter: (value) => {
            return moment(value).format("LLL");
          },
        },
        {
          key: "payment_gateway",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "payment_method",
          class: "c_touchpt_header",
          visible: true,
        },
        {
          key: "status",
          class: "text-center c_touchpt_header",
          label: "Payment Status",
          visible: true,
        },
        {
          key: "amount",
          label: "Actual Amount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "coupon_code",
          label: "Coupon Code",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "taxable_amount",
          label: "Taxable Amount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "tax",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "coupon_discount",
          label: "Coupon discount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "total_amount",
          label: "Total Amount",
          visible: true,
          class: "c_touchpt_header",
        },
        {
          key: "currency",
          label: "Country",
          visible: true,
          class: "c_touchpt_header",
        },
      ],
      isNotesPopup: false,
      isDetailsPopup: false,
      orderId: "",
      attachment: "",
      attachment_url: "",
      edit: {
        attachment_url: null,
      },
      tooltipMessage: "Click to view",
    };
  },
  methods: {
    removeQueryParam() {
      let uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
        let clean_uri = uri.substring(0, uri.indexOf("?"));
        window.history.replaceState({}, document.title, clean_uri);
      }
    },
    searchFor() {
      if (this.filter.length > 1) {
        this.fetchData();
      } else if (this.filter.length == 0) {
        this.fetchData();
        this.currentPage = 1;
      }
    },
    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) {
        this.removeQueryParam();
        this.fetchData();
        this.currentPage = 1;
      }
    },
    readFile(e, txt) {
      if (txt == "attachment") {
        this.attachment = e.target.files[0];
        if (this.attachment.size > 2 * 1000 * 1000) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "error",
            title: "Uploaded File is More than 2MB",
          });
          this.$refs.attachment.reset();
          this.attachment_url = "";
          this.edit.attachment_url = "";
          this.activeTabis.attachment = "";
          return false;
        } else {
          this.attachment_url = URL.createObjectURL(this.attachment);
          this.edit.attachment_url = URL.createObjectURL(this.attachment);
        }
        return true;
      }
    },
    filterPage() {
      if (this.pageNo.length > 0) {
        this.params = `&page=${this.pageNo}`;
        this.fetchData();
        this.currentPage = 1;
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async fetchData(txt) {
      console.log(this.filter, "filter");
      this.$store.commit("loader/updateStatus", true);
      const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
      let request = indonesia_payment?.getPaymentList;
      request = `${request}?order_status=${encodeURIComponent("all")}&page=${
        this.currentPage ?? 1
      }&q=${encodeURIComponent(this.filter ?? "")}&transaction_uuid=${
        txt ?? ""
      }`;
      axios
        .get(`${apiEndpoint}${request}`)
        .then((response) => {
          const res = response?.data;
          if (res) {
            this.tableData = res?.response;
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          console.log(err);
          this.$store.commit("loader/updateStatus", false);
        });
    },
    openDetails(screenshot, note, attachment) {
      this.screenshot = screenshot;
      this.attachment = attachment;
      if (note != null) {
        this.message = note;
      }
      this.isDetailsPopup = true;
    },
    openNotes(id, screenshot) {
      console.log(id);
      this.orderId = id;
      this.isNotesPopup = true;
      this.screenshot = screenshot;
    },
    async handleClick(status) {
      this.$store.commit("loader/updateStatus", true);
      const apiEndpoint = process.env.VUE_APP_INDONESIA_PAYMENT;
      let request = indonesia_payment?.confirmPayment;
      let dataAppend = new FormData();
      dataAppend.append("order_id", this.orderId);
      dataAppend.append("note", this.message);
      if (this.attachment) {
        dataAppend.append("note_attachment", this.attachment);
      }
      // To reject the payment
      if (status) {
        dataAppend.append("status", status);
      }
      axios
        .post(`${apiEndpoint}${request}`, dataAppend)
        .then((response) => {
          const res = response?.data;
          if (
            res?.response?.payment_status == 1 ||
            res?.response?.payment_status == 2
          ) {
            this.$store.commit("toast/updateStatus", {
              status: true,
              icon: "success",
              title:
                res?.response?.payment_status == 2
                  ? "Payment Rejected"
                  : "Payment Approved",
            });
            this.isNotesPopup = false;
            this.fetchData(this.activeTab, this.currentPage);
          }
          this.$store.commit("loader/updateStatus", false);
        })
        .catch((err) => {
          this.$store.commit("toast/updateStatus"),
            {
              status: true,
              icon: "error",
              title: "Something went wrong",
            };
          this.$store.commit("loader/updateStatus", false);
          console.log(err);
        });
    },
  },
  activeTab: {
    handler: function (value) {
      if (value == "trash") {
        let index = this.fields.findIndex((item) => item.key == "delete");
        this.fields.splice(index, 1);
        let edit_index = this.fields.findIndex((item) => item.key == "edit");
        this.fields.splice(edit_index, 1);
      } else if (value == "all" || value == "active") {
        if (this.fields[this.fields.length - 1].key != "delete") {
          this.fields.splice(this.fields.length, 0, { key: "edit" });
          this.fields.splice(this.fields.length, 0, { key: "delete" });
        }
      }
    },
  },
  watch: {
    currentPage: {
      handler: function (value) {
        if (typeof value != undefined) {
          this.fetchData();
          this.pageNo = this.currentPage;
        }
      },
    },
  },
  created() {
    if (this.$route.query?.uid) {
      this.fetchData(this.$route.query.uid);
      this.filter = this.$route.query?.uid;
    } else {
      this.fetchData();
    }
  },
};
