<template>
  <Layout>
    <PageHeader :title="title" :items="items"></PageHeader>
    <div class="row" id="article">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-3">
                <div class="input-group">
                  <input
                    name="title"
                    class="form-control bg-light border-0 small"
                    placeholder="Search Title"
                    aria-label="Search"
                    aria-describedby="basic-addon2"
                    value=""
                    v-model="filter"
                    v-on:keyup="search"
                  />
                  <div class="input-group-append">
                    <button
                      id="myBtn"
                      class="btn btn-search"
                      @click="searchFor"
                    >
                      <i class="fas fa-search fa-sm"> </i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-responsive mb-4">
              <b-table
                striped
                bordered
                :items="tableData.data"
                :fields="fields"
                :sort-by.sync="sortBy"
                :per-page="0"
                :current-page="currentPage"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                class="table-bordered table-hover"
              >
                <template v-slot:cell(coupon_code)="row">
                  <span class="text-center">
                    <template v-if="row.item.coupon !== null">
                      <span class="">{{ row.item.coupon.code }}</span>
                    </template>
                    <template v-else>
                      <span class="">---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(coupon_discount)="row">
                  <span class="text-center">
                    <template v-if="row.item.coupon !== null">
                      <span class="">{{ row.item.coupon.discount }}</span>
                    </template>
                    <template v-else>
                      <span class="">---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(taxable_amount)="row">
                  <span class="text-center">
                    <span class="" v-if="row.item.taxable_amount">{{
                      row.item.taxable_amount
                    }}</span>
                    <span class="" v-else>---</span>
                  </span>
                </template>
                <template v-slot:cell(payment_gateway)="row">
                  <span
                    class="text-capitalize"
                    :class="
                      row.item.payment_gateway[0].name == 'razorpay'
                        ? 'badge badge-success'
                        : 'badge badge-warning'
                    "
                  >
                    {{ row.item.payment_gateway[0].name }}
                  </span>
                </template>
                <template v-slot:cell(order_items)="row">
                  <template v-for="(item, index) in row.item.order_items">
                    <div :key="index">
                      <span v-if="item.title">{{ item.title }}</span>
                      <span v-else>---</span>
                    </div>
                  </template>
                </template>
                <template v-slot:cell(total_amount)="row">
                  <span v-if="row.item.order && row.item.order.total_amount">{{
                    row.item.order?.currency + " " + row.item.order.total_amount
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(amount)="row">
                  <span v-if="row.item.order && row.item.order.amount">{{
                    row.item.order?.currency + " " + row.item.order.amount
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(order_id)="row">
                  <template v-if="row.item">
                    <span v-if="row.item.transaction_uuid">
                      {{ row.item.transaction_uuid }}
                    </span>
                    <span v-else>---</span>
                  </template>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(order_number)="row">
                  <router-link
                    v-if="row.item.order && row.item.order.order_number"
                    :to="{
                      name: 'order',
                      query: { orderNumber: row.item?.order?.order_number },
                    }"
                  >
                    <span
                      style="
                        border-bottom: 1px solid #5664d2;
                        word-break: break-all;
                      "
                    >
                      {{ row.item.order.order_number }}
                    </span>
                  </router-link>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(payment_method)="row">
                  <span v-if="row.item && row.item.payment_method">
                    {{ row.item.payment_method }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(taxable_amount)="row">
                  <span v-if="row.item.order && row.item.order.taxable_amount">
                    {{ row.item.order.taxable_amount }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(tax)="row">
                  <span v-if="row.item && row.item.tax">
                    {{ row.item.tax }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(currency)="row">
                  <span
                    v-if="row.item.currency == 'INR'"
                    class="badge badge-primary"
                  >
                    India
                  </span>
                  <span
                    v-if="row.item.currency == 'IDR'"
                    class="badge badge-info"
                  >
                    Indonesia
                  </span>
                </template>
                <template v-slot:cell(member_name)="row">
                  <span v-if="row.item.member != null"
                    >{{ row.item.member.fname }}
                    {{ row.item.member.lname }}
                  </span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(payment_date)="row">
                  <span v-if="row.item && row.item.payment_date">{{
                    row.item.payment_date
                  }}</span>
                </template>
                <template v-slot:cell(details)="row">
                  <b-button
                    @click="
                      openDetails(
                        row?.item?.scrennshot,
                        row?.item?.payment?.note,
                        row?.item?.payment?.note_attachment
                      )
                    "
                    class="badge badge-secondary"
                    :disabled="row?.item?.currency == 'INR'"
                  >
                    View Details
                  </b-button>
                </template>
                <template v-slot:cell(status)="row">
                  <div
                    class="d-flex justify-content-center align-items-center"
                    v-if="row.item.status"
                  >
                    <span
                      class="badge border border-secondary text-capitalize"
                      >{{ row.item.status.replace(/_/g, " ") }}</span
                    >
                  </div>
                </template>
              </b-table>
            </div>
            <template v-if="tableData.total_pages > 1">
              <div class="data_paginate">
                <div
                  class="dataTables_paginate paging_simple_numbers d-flex justify-content-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <b-pagination
                      v-model="currentPage"
                      first-number
                      last-number
                      :total-rows="tableData.total"
                      :total-pages="tableData.total_pages"
                      :per-page="tableData.per_page"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </template>
            <div class="no_content py-4" v-if="tableData?.data?.length == 0">
              <div style="display: grid; justify-items: center">
                <img src="../../../../../public/noData.svg" alt="" />
                <span class="no_content_text">No Data Available</span>
              </div>
            </div>
            <b-modal
              id="noteModal"
              size="lg"
              v-model="isNotesPopup"
              no-close-on-backdrop
              centered
              title="Confirmation"
            >
              <div class="p_screenshot">
                <div class="d-flex justify-content-between">
                  <h5 class="mb-2">Screenshot of Payment</h5>
                  <p
                    class="badge badge-success p-1"
                    style="cursor: pointer"
                    @click="show(screenshot)"
                    v-if="screenshot"
                  >
                    View Screenshot
                  </p>
                </div>
                <div class="p_viewer" v-if="screenshot">
                  <viewer :images="[screenshot]">
                    <img
                      :src="`${screenshot}`"
                      alt=""
                      height="100%"
                      width="100%"
                    />
                  </viewer>
                </div>
                <div class="no_content" v-else>
                  <div style="display: grid; justify-items: center">
                    <img src="../../../../../public/noData.svg" alt="" />
                    <span class="no_content_text">No Screenshot Available</span>
                  </div>
                </div>
              </div>
              <template v-slot:modal-footer class="p-0">
                <div class="p_screenshot">
                  <b-form-group class="mb-4">
                    <h5 class="mb-3" for="image">Attachment</h5>
                    <b-form-file
                      id="image"
                      accept="image/*"
                      placeholder="Choose a file or drop it here..."
                      @change="readFile($event, 'attachment')"
                      ref="attachment"
                    ></b-form-file>
                    <template v-if="attachment_url">
                      <img
                        :src="attachment_url"
                        width="128px"
                        height="128px"
                        ref="attchment_url"
                        style="object-fit: contain; margin-top: 5px"
                      />
                    </template>
                  </b-form-group>
                  <b-input-group id="person2-form" class="">
                    <h5 class="mb-3">Note</h5>
                    <b-form-textarea
                      id="message"
                      v-model="message"
                      placeholder="Type note [Eg: Payment successful]"
                      rows="4"
                      class="mb-3 p_screenshot_ta"
                    >
                    </b-form-textarea>
                  </b-input-group>
                  <div class="d-flex justify-content-end">
                    <b-button
                      @click="handleClick(1)"
                      type="submit"
                      class="bg-primary mr-2"
                      >Confirm</b-button
                    >
                  </div>
                </div>
              </template>
            </b-modal>
            <b-modal
              id="details"
              size="lg"
              v-model="isDetailsPopup"
              no-close-on-backdrop
              centered
              hide-header-close
              ok-only
              ok-title="Close"
              title="Details"
            >
              <div class="pb-4 p_screenshot">
                <div style="border-bottom: 1px solid #c0c7d3" class="pb-4">
                  <div class="d-flex justify-content-between">
                    <h5 class="mb-2">Payment Screenshot</h5>
                    <p
                      class="badge badge-success p-1"
                      style="cursor: pointer"
                      @click="show(screenshot)"
                      v-if="screenshot"
                    >
                      View Screenshot
                    </p>
                  </div>
                  <div class="p_viewer" v-if="screenshot">
                    <viewer :images="[screenshot]">
                      <img
                        :src="`${screenshot}`"
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </viewer>
                  </div>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text"
                        >No Screenshot Available</span
                      >
                    </div>
                  </div>
                </div>
                <div style="border-bottom: 1px solid #c0c7d3" class="pb-4">
                  <div class="d-flex justify-content-between mt-4">
                    <h5 class="mb-2">Note Attachment</h5>
                    <p
                      class="badge badge-success p-1"
                      style="cursor: pointer"
                      @click="show(attachment)"
                      v-if="attachment != null"
                    >
                      View Attachment
                    </p>
                  </div>
                  <div class="p_viewer" v-if="attachment != null">
                    <viewer :images="[attachment]">
                      <img
                        :src="`${attachment}`"
                        alt=""
                        height="100%"
                        width="100%"
                      />
                    </viewer>
                  </div>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text"
                        >No Note Attachment Available</span
                      >
                    </div>
                  </div>
                </div>
                <div>
                  <h5 class="mt-4 mb-2">Note</h5>
                  <p v-if="message">{{ message }}</p>
                  <div class="no_content" v-else>
                    <div style="display: grid; justify-items: center">
                      <img src="../../../../../public/noData.svg" alt="" />
                      <span class="no_content_text">No Note Available</span>
                    </div>
                  </div>
                </div>
              </div>
            </b-modal>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../../mixins/request";
import IndoMixin from "../../../../mixins/ModuleJs/indonesia-payment";
import VueViewer from "v-viewer";
import "viewerjs/dist/viewer.css";
import Vue from "vue";
Vue.use(VueViewer);

export default {
  mixins: [MixinRequest, IndoMixin],
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Payment",
      items: [
        {
          text: "Home",
          href: "/",
        },
        {
          text: "List",
        },
      ],
    };
  },
  methods: {
    show(img) {
      this.$viewerApi({
        images: [img],
      });
    },
  },
};
</script>
